import React from "react";
import { Polar } from "react-chartjs-2";
import { fundColors } from "../../helpers/colors";
import { formatCurrency } from "../../helpers/utils";

const PolarChart = ({ values = [0, 0, 0, 0] }) => {
  const data = {
    datasets: [
      {
        data: values,
        backgroundColor: fundColors,
        hoverBackgroundColor: fundColors,
        hoverBorderColor: "#fff",
        borderWidth: 4,
      },
    ],
  };

  // const option = {
  //   tooltips: {
  //     callbacks: {
  //       title: function (tooltipItem, data) {
  //         const total = values.reduce((a, b) => a + b, 0);
  //         const currentValue = data.datasets[0].data[tooltipItem[0].index];
  //         const percentage = parseFloat(
  //           ((currentValue / total) * 100).toFixed(1)
  //         );
  //         return `${percentage}%`;
  //       },
  //       label: function (tooltipItem, data) {
  //         const dataset = data.datasets[tooltipItem.datasetIndex];
  //         const currentValue = dataset.data[tooltipItem.index];
  //         return `${formatCurrency(currentValue)} Dana`;
  //       },
  //       afterLabel: function (tooltipItem) {
  //         const status =
  //           tooltipItem.index === 0
  //             ? "dari APBN"
  //             : tooltipItem.index === 1
  //             ? "dari APBD"
  //             : tooltipItem.index === 2
  //             ? "dari Swasta"
  //             : tooltipItem.index === 3
  //             ? "dari Lainnya"
  //             : "";
  //         return status;
  //       },
  //     },
  //   },
  // };

  return (
    <Polar
      width={100}
      height={100}
      data={data}
      options={{
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              const total = values.reduce((a, b) => a + b, 0);
              const currentValue = data.datasets[0].data[tooltipItem[0].index];
              const percentage = parseFloat(
                ((currentValue / total) * 100).toFixed(1)
              );
              return `${percentage}%`;
            },
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const currentValue = dataset.data[tooltipItem.index];
              return `${formatCurrency(currentValue)} Dana`;
            },
            afterLabel: function (tooltipItem) {
              const status =
                tooltipItem.index === 0
                  ? "dari APBN"
                  : tooltipItem.index === 1
                  ? "dari APBD"
                  : tooltipItem.index === 2
                  ? "dari Swasta"
                  : tooltipItem.index === 3
                  ? "dari Lainnya"
                  : "";
              return status;
            },
          },
        },
        scale: {
          ticks: {
            callback: function (value) {
              return formatCurrency(value);
            },
          },
        },
      }}
    />
  );
};

export default PolarChart;
